import Link from "next/link";
import MainContentLayout from "../../../customLayout/mainContentLayout";
import IconDocument_list from "../../../icons/IconDocument_list";
import Image from "next/image";
import IconFilm from "../../../icons/IconFilm";
import IconPlayFill from "../../../icons/IconPlayFill";
import { Button } from "@mui/material";
import { useEffect, useState } from "react";

export default function BlogSection() {
  const items = [
    { img: '/images/test blog photo.jpg', title: 'با فرق بین عطر تابستانی و زمستانی به طور کامل آشنا شوید', type: 'پوشش و آراستگی', body: 'برای خرید بهترین عطر زنانه معیارهای مختلفی وجود دارد. تعدادی از این معیارها، علاقه به رایحه، ماندگاری عطر، زمستانی یا تابستانی' },
    { img: '/images/test blog photo.jpg', title: 'با فرق بین عطر تابستانی و زمستانی به طور کامل آشنا شوید', type: 'پوشش و آراستگی', body: 'برای خرید بهترین عطر زنانه معیارهای مختلفی وجود دارد. تعدادی از این معیارها، علاقه به رایحه، ماندگاری عطر، زمستانی یا تابستانی' },
    { img: '/images/test blog photo.jpg', title: 'با فرق بین عطر تابستانی و زمستانی به طور کامل آشنا شوید', type: 'پوشش و آراستگی', body: 'برای خرید بهترین عطر زنانه معیارهای مختلفی وجود دارد. تعدادی از این معیارها، علاقه به رایحه، ماندگاری عطر، زمستانی یا تابستانی' }
  ]

  const videos = [
    { img: '/static-image/blog-media.jpg', content: 'اموزش شستشو پا' },
    { img: '/static-image/blog-media.jpg', content: 'اموزش شستشو پا' }
  ]

  const [isTablet, setIsTablet] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      setIsTablet(window.innerWidth <= 992)
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const displayedItems = isTablet ? items.slice(0, 1) : items.slice(0, 3)

  return (
    <div className="w-full bg-light-80 box-border pb-24 max-md:pb-16 [&>*]:1170:!max-w-1170 [&>*]:!max-w-970 [&>*]:!mx-auto">
      <div className="grid grid-cols-1 md:grid-cols-4 justify-between max-xl:flex items-stretch gap-3 992:max-w-screen-992 1170:max-w-screen-1170 mx-auto !max-w-[85rem] w-[82rem] max-xl:w-full max-xl:justify-center max-992:w-[790px] max-992:justify-evenly max-md:flex-col max-md:w-auto xl:w-auto xl:m-auto">
        <div className="col-span-3 justify-between items-center pt-8 text-primary-500 flex flex-col max-xl:w-auto max-992:w-[330px] max-md:max-w-calc w-auto max-md:pt-4 max-md:m-auto max-md:w-auto">
          <div className="h-12 w-full flex justify-between items-center mb-3 max-992:pl-2">
            <Link href={'#'} className="text-sm">
              <span className="flex items-center w-full">
                <IconDocument_list width={46} height={46} />
                <h4 className="text-xl font-semibold">در بلاگ اُردمی بخوانید</h4>
              </span>
            </Link>
            <Link href={'#'} className="text-sm font-medium">+ مشاهده همه</Link>
          </div>
          <div className="flex gap-4 max-992:w-full max-xl:w-[698px]">
            {displayedItems.map((item, index) => (
              <div key={index} className="bg-gray-50 grid shadow-[0_1px_3px_rgba(0,0,0,0.2)] hover:shadow-[0_3px_6px_rgba(0,0,0,0.3)] transition-shadow duration-300 ease-in-out h-[475px] max-xl:w-[222px] max-xl:h-auto max-992:w-auto">
                <div className="overflow-hidden transition-all duration-300 max-xl:min-h-[145px]">
                  <Image
                    width={280.16}
                    height={182.7}
                    src={item.img}
                    alt={item.title}
                    layout="responsive"
                    className="brightness-[.85] hover:brightness-100 transition-all duration-500 ease-in-out"
                  />
                </div>
                <h2 className="p-2 font-medium text-lg transition-colors duration-300 ease-in-out hover:text-black mt-2 max-xl:h-[62px] max-xl:overflow-hidden max-md:max-h-[51px] max-xl:px-2 max-xl:p-0 max-xl:-h-[52px]">
                  <Link href={'#'}>{item.title}</Link>
                </h2>
                <span className="text-[#6b6b6b] p-2 transition-colors duration-300 ease-in-out hover:text-[#b20b51] max-xl:px-4 max-xl:p-0">
                  <Link href={'#'}>{item.type}</Link>
                </span>
                <p className="p-2 text-[15px] text-[#666] font-normal leading-8 max-xl:overflow-hidden max-xl:h-[90px] max-md:max-h-[90px] max-xl:p-0 max-xl:px-2">
                  {item.body}
                </p>
                <div className="w-full flex justify-end p-2 transition-all duration-300 ease-in-out">
                  <Button
                    sx={{
                      fontSize: '14px !important',
                      marginBottom: '8px',
                      marginLeft: '12px',
                      fontWeight: '400',
                      border: '1px #b20b51 solid',
                      width: '100px',
                      height: '35px',
                      backgroundColor: 'white',
                      borderRadius: '3px',
                      boxShadow: '2px 2px 4px rgba(0,0,0,0.2)',
                      ':hover': {
                        background: '#b20b51',
                        color: 'white'
                      }
                    }}
                  >
                    ادامه مطلب
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="col-span-1 flex flex-col max-md:w-webkit-fill-available max-md:m-auto pt-8">
          <div className="h-12 mb-3 w-full flex flex-row justify-between items-center text-primary-500">
            <Link href={'#'} className="flex items-center font-semibold text-sm">
              <IconFilm width={22} height={22} className="ml-2" />
              تازه‌های تصویری
            </Link>
            <Link href={'#'} className="text-sm font-medium">+ مشاهده همه</Link>
          </div>

          <div className="flex-1">
            <div className="size-full">
              <div className="h-full w-full flex flex-col gap-4">
                {videos.map((video, index) => (
                  <div key={index} className="relative w-full h-[228px] flex-1 flex flex-col shadow-sm hover:shadow-md transition-all overflow-hidden rounded-sm max-992:w-auto max-xl:h-[200px] max-xl:w-[220px]">
                    <Link href={'#'} className="h-full w-full relative">
                      <Image fill src={video.img} alt="" className="object-cover" />
                    </Link>
                    <span className="bg-primary-500/70 w-12 h-12 flex justify-center items-center rounded-full absolute top-[calc(50%-1.5rem)] right-[calc(50%-1.5rem)]">
                      <IconPlayFill width={32} height={32} className="text-white" />
                    </span>
                    <div className="absolute bottom-[2px] right-4 bg-opacity-60 text-white text-lg px-4 py-2 rounded font-medium">
                      {video.content}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
